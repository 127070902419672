.popover-button{
    position: relative;
    .dots{
        border:none;
        outline:none;
        background:transparent;
        display:flex;
        cursor:pointer;
        padding:0;
        margin:0;
        box-sizing: border-box;
        justify-content:center;
        align-items:center;
        user-select:none;
        color: #252525;
        font-family: 'Montserrat', sans-serif;
        font-size: 19px;
        // width:100%;
        // height:100%;
        font-weight: 700;
        letter-spacing:4px;
    }

    .popover-action{
        font-family: 'Montserrat', sans-serif;
        font-size:14px;
        font-weight:500;
        cursor:pointer;
        border:none;
        border-radius:8px;
        background-color:#fff;
        -webkit-box-shadow: 0px 0px 18px -9px rgba(66, 68, 90, 1);
        -moz-box-shadow: 0px 0px 18px -9px rgba(66, 68, 90, 1);
        box-shadow: 0px 0px 18px -9px rgba(66, 68, 90, 1);
        position: absolute;
        top:-40px;
        left:-150px;
        padding: 9px;
        span{
          display: flex;
          justify-content: center;
          align-items: center;
          width:134px;
          height:32px;
          border-radius:8px;
          transition: .2s ease;
          &:hover{
            background-color:rgba(217, 217, 217, .2)
          }
        }
      }
}