.page-login {
	width: 100vw;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;

	.left {
		width: 60%;
		height: 100%;
		display: flex;
		flex-direction: column;
		position:relative;
		justify-content: flex-start;
		align-items: center;
		.back-to-main-page-icon-black{
			display:none;
			@media(max-width: $breakpoint-sm){
				display:block;
			}
		}
		.logo-container{
			width:360px;
			height:30%;
			padding-top:40px;
			display:flex;
			justify-content: flex-start;
			align-items: flex-start;
			justify-self: flex-start;
			@media(max-width: $breakpoint-sm){
				width:100%;
				height: auto;
				margin-bottom: 50px;
			}
		}

		@media(max-width:$breakpoint-xl){
			width: 50%;
		}

		@media(max-width:$breakpoint-md){
			width: 100%;
		}
	}

	.right {
		width: 40%;
		height: 100%;
		background-image: url('../../assets/images/login/login.png');
		background-color: #fec853;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;

		@media(max-width:$breakpoint-xl){
			width: 50%;
		}

		@media(max-width:$breakpoint-md){
			display: none;
		}
	}

	.input-container {
		width: 100%;
		margin-top:20px;
	}

	.main-page-title {
		display: flex;
		width: 100%;
		flex-direction: column;
		justify-content: center;
		color: #333033;
		font-size: 36px;
		font-family: 'Montserrat', sans-serif;
		font-style: normal;
		font-weight: 700;
		line-height: normal;
		margin-bottom: 30px;
	}

	.login-elements-wrapper-with-captcha {
		flex-direction: column;
	}

	.login-elements-wrapper, .login-elements-wrapper-with-captcha {
		margin-top: 95px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		@media(max-width:$breakpoint-sm){
			margin-top:60px;
		}
		.login-vertical-elements {
			display: flex;
			flex-direction: column;
			width: 100%;
			align-items: center;
			justify-content: center;
			.login-button {
				border-radius: 100px;
				background: #98D9C2;
				color: #252525;
				padding: 15px 30px;
				text-align: center;
				font-family: 'Montserrat', sans-serif;
				font-size: 16px;
				font-style: normal;
				font-weight: 600;
				line-height: normal;
				border: none;
				outline: none;
				cursor:pointer;
				margin:20px 0;
			}
			p {
				color: #555;
				text-align: center;
				font-family: 'Montserrat', sans-serif;
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
				margin:50px 0 87px 0;
				@media(max-width:$breakpoint-sm){
					margin:20px 0 30px 0;
				}
				.create {
					color: #555;
					font-family: 'Montserrat', sans-serif;
					font-size: 14px;
					font-style: normal;
					font-weight: 700;
					line-height: normal;
					text-decoration-line: underline;
					cursor: pointer;
				}
			}
			a {
				color: #555;
				text-align: center;
				font-family: 'Montserrat', sans-serif;
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
				text-decoration-line: underline;
			}
		}

		.save-login {
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: row;

			p {
				margin-left: 20px;
				color: #555;
				font-family: 'Montserrat', sans-serif;
				font-size: 12px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
			}
		}
	}

	a {
		color: #333033;
		text-align: right;
		leading-trim: both;
		text-edge: cap;
		font-size: 14px;
		font-family: 'Montserrat', sans-serif;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		text-decoration-line: underline;
	}
	.p {
		color: #333033;
		text-align: right;
		leading-trim: both;
		text-edge: cap;
		font-size: 14px;
		font-family: 'Montserrat', sans-serif;
		font-style: normal;
		font-weight: 400;
		line-height: normal;

		a {
			font-weight: 700;
		}
	}
}

@media screen and (max-width: $breakpoint-sm) {
	.page-login {
		.right {
			display: none;
		}
		.left {
			width: 100%;
			padding: 0 25px;
		}
		.main-page-title {
			width: 100%;
		}

		.login-elements-wrapper {
			flex-direction: column-reverse;

			.save-login {
				margin-top: 10px;
			}

			p {
				// margin-bottom: 10px;
			}
		}

		.login-elements-wrapper {
			button {
				margin-top: 20px;
			}
		}
		min-height:600px;
	}
}
