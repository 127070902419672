.section-wrapper {
  height:100%;
  .button-container{
    display:flex;
    align-items:center;
    justify-content:flex-start;
    .documentation-link{
      color: #333033;
      font-family: 'Montserrat', sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      text-decoration-line: underline;
      margin-left:30px;
      &:hover{
        color:#939393;
      }
    }
  }
  .key-list-wrapper{
    .grid-container{
      padding:60px 0;
      .table-h{
        color: #555;
        font-family: 'Montserrat', sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        min-width:120px;
      }
    }
    .table-item{
      color: #252525;
      font-family: 'Montserrat', sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }
}
.download-button{
  font-family: 'Montserrat', sans-serif;
  font-size:14px;
  font-weight:500;
  cursor:pointer;
  border:none;
  border-radius:8px;
  background-color:#fff;
  -webkit-box-shadow: 0px 0px 18px -9px rgba(66, 68, 90, 1);
  -moz-box-shadow: 0px 0px 18px -9px rgba(66, 68, 90, 1);
  box-shadow: 0px 0px 18px -9px rgba(66, 68, 90, 1);
  position: absolute;
  top:-40px;
  left:-130px;
  padding: 9px;
  span{
    display: flex;
    justify-content: center;
    align-items: center;
    width:134px;
    height:32px;
    border-radius:8px;
    transition: .2s ease;
    &:hover{
      background-color:rgba(217, 217, 217, .2)
    }
  }
}
.loader-container{
  display:flex;
  justify-content:center;
  align-items:center;
  background-color:#fff;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  padding:20px;
}
.enter-name-container{
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content:space-between;
  padding:50px 60px;
  width:460px;
  height:255px;
  background-color:#fff;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  .enter-name-header{
    color: #252525;
    font-family: 'Montserrat', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .error-message{
    color:red;
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .enter-name-input{
    width:100%;
    border-bottom:1px solid #949494;
    padding:12px 0;
    color: #555;
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    &::placeholder{
      color: #939393;
    }
    &:focus{
      border-bottom:1px solid #6BA292;
    }
  }
  .buttons-container{
    display:flex;
    justify-content:space-between;
    align-items: center;
    flex-wrap:wrap;
    height:40px;
    width:160px;
    .button-cancel{
      height:15px;
      color: #252525;
      cursor:pointer;
      text-align: center;
      font-family: 'Montserrat', sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-decoration-line: underline;
      &:hover{
        color:#939393;
      }
    }
  }
}
.container-error{
  height:273px;
}
.copy-key-container{
  width:550px;
  height:280px;
  border-radius: 10px;
  background: #FFF;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);
  display:flex;
  flex-direction:column;
    align-items:center;
  justify-content:flex-start;
  padding: 0 18px 50px 18px;
  .close-container{
    width:100%;
    padding:20px 0;
    display:flex;
    justify-content:flex-end;
  }
  .copy-key-header{
    color: #252525;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .enter-name-input{
    border-radius: 50px;
    background: #E8E8E8;
    padding:18px 25px;
    color: #333033;
    width:100%;
    max-width:550px;
    font-family: 'Montserrat', sans-serif;
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin:35px 0;
  }
}
