.sidebar-container {
	position: absolute;
	left: 0;
	top: 105px;
	bottom: 0;
	width: 250px;
	background: #252525;
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-direction: column;
	z-index: 10;
	.user-menu {
		transition: 1;
		box-sizing: border-box;
		position: absolute;
		bottom: 50px;
		left: 230px;
		background: #ffffff;
		border-radius: 10px;
		border: 1px solid #9d9d9d;
		padding: 24px 28px;
		width: 162px;

		ul {
			li {
				margin-bottom: 14px;
				color: #2c2c2c;
				font-family: Inter;
				font-size: 16px;
				font-style: normal;
				font-weight: 500;
				line-height: 26.4px;
			}

			li:last-child {
				border-top: 1px solid #000;
				padding-top: 15px;
				margin-bottom: 0;
				position: relative;
			}
		}
	}
	nav {
		width: 100%;
		padding-top: 71px;
		@media(min-width:$breakpoint-md) and (max-height:790px){
			padding-top:20px;
		}
		.close-button-container{
			display:none;
		}
		.sidebar-nav-list {
			width: 100%;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			.sidebar-li {
				width: 90%;
				padding: 18px 0 18px 22px;
				margin: 5px 0;
				display: flex;
				justify-content: flex-start;
				align-items: center;
				flex-direction: row;
				border-radius: 10px;
				cursor: pointer;
				position: relative;
				.icon {
					transition: 600ms;
					width:18px;
					height:18px;
				}

				.active-icon {
					filter: invert(88%) sepia(27%) saturate(360%) hue-rotate(100deg) brightness(160%) contrast(93%);
				}

				.menu-element-title {
					color: #ACACAC;
					font-family: 'Montserrat', sans-serif;
					font-size: 14px;
					margin-left: 23px;
					font-style: normal;
					font-weight: 500;
					line-height: normal;
					transition: 600ms;
				}

				.active-p {
					color: #98D9C2;
				}

				.selected-li {
					position: absolute;
					top: 2px;
					right: -12px;
					background: #98D9C2;
					width: 5px;
					height:50px;
					border-radius: 10px 0 0 10px;
				}
			}

			.margin {
				margin-bottom: 70px;
			}

			.selected-background-li {
				background: rgba(217, 217, 217, 0.1);
				transition: 500ms;
			}

			.sidebar-li:hover {
				.icon {
					filter: invert(88%) sepia(27%) saturate(360%) hue-rotate(100deg) brightness(160%) contrast(93%);
				}
				p {
					color: #98D9C2;
				}
			}
		}
	}

	.profile-wrapper {
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 40px 0;
		width: 100%;
		border-top:0.5px solid #ACACAC;
		.container{
			display: flex;
			justify-content: flex-start;
			align-items: center;
			width:90%;
			padding-left:22px;
			cursor:pointer;
			.logout{
				margin-left:20px;
				color: #ACACAC;
				font-family: 'Montserrat', sans-serif;
				font-size: 14px;
				font-style: normal;
				font-weight: 500;
				line-height: normal;
			}
			&:hover{
				.logout{
					color: #98D9C2;
				}
				svg{
					color: #98D9C2;
					fill: #98D9C2;
					filter: invert(88%) sepia(27%) saturate(360%) hue-rotate(100deg) brightness(160%) contrast(93%);
				}
			}
		}
	}
}

@media screen and (max-width: $breakpoint-md) {
	.sidebar-container {
		top: 0;
		left: unset;
		right: -260px;
		width: 260px;
		background: #252525;
		position:fixed;
		z-index:101;
		overflow-y:scroll;
		display:flex;
		flex-direction:column;
		justify-content:space-between;
		transition: ease-out 0.5s;
		&.active{
			right: 0;
		}
		nav {
			height: 100%;
			padding:0;
			.close-button-container{
				width:90%;
				display:flex;
				justify-content:flex-end;
				align-items:center;
				padding:25px 0 25px 0;
			}
			.sidebar-nav-list {
				padding-left: 0;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: flex-start;
				.sidebar-li {
					width: 90%;
					padding: 18px 15px;
					display: flex;
					flex-direction: row;
					justify-content: center;
					align-items: center;

					.menu-element-title {
						width: 100%;
						margin-left:0;
						font-family: 'Montserrat', sans-serif;
						font-size: 16px;
						font-style: normal;
						font-weight: 400;
						line-height: normal;
					}

					img {
						margin-right: 10%;
					}

					.selected-li {
						display: none;
					}
					.selected-background-li {
						width: 100%;
						height: 100%;
						left: 0;
						right: 0;
					}

					.icon {
						display: none;
					}
				}
				.margin {
					margin: 0;
				}
			}
		}

		.profile-wrapper {
			display: flex;
			flex-direction: row;
			justify-content: space-around;
			align-items: center;
		}
	}
}
